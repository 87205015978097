
























































































import Vue from 'vue'
import Component from 'vue-class-component'
import TemplateService from '@/services/Template.service'

@Component({})
export default class DocumentsPage extends Vue {
  templateService = new TemplateService()

  templates: any[] = []

  boletimTemplate: any = {}

  form = {
    file: null,
    newName: '',
    template: null
  }

  documentFormState = {
    loading: false
  }

  state = {
    loading: false
  }

  $http!: any

  async mounted() {
    this.loadBoletimTemplate()
  }

  async loadBoletimTemplate() {
    this.state.loading = true

    const response = await this.templateService.templates({ tipo: 'BOLETIM_SUBSCRICAO' })

    this.state.loading = false

    if (response.length) {
      this.boletimTemplate = response[0]
    }
  }

  onFileChange(event) {
    this.form.file = event.target.files[0]
    this.form.newName = (this.form.file as any).name
  }

  onSubmit() {
    const tService = new TemplateService(this.$http)

    this.documentFormState.loading = true

    tService
      .save({
        file: this.form.file,
        templateId: this.boletimTemplate.id
      })
      .then(async(response) => {
        this.boletimTemplate = response
        await this.onSync(this.boletimTemplate.arquivo)
      })
      .catch((request) => {
        const message = request.response.data
        alert(message)
      })
      .then(() => {
        this.documentFormState.loading = false
      })
      .then(() => {
        this.form.file = null
        this.form.newName = '';
        (this.$refs.fileInput as any).value = ''
      })
  }

  async onSync(doc) {
    const html = await this.templateService.parseDocxToHtml({ source: doc.link })

    return this.templateService
      .syncBoletimTemplate({ html })
      .then((response) => {
        console.log(response)
        alert('Template sincronizado com sucesso.')
      })
      .catch((response) => {
        console.log(response)
        alert('Erro ao sincronizar template com o JSReport. Isso pode afetar a impressão de PDFs, contate o suporte.')
      })
  }
}

