import axios, { AxiosInstance } from 'axios'

export default class TemplateService {
  private DOCUMENT_ENDPOINT = 'https://documento-dot-smu-devs.appspot.com';
  private PARSER_ENDPOINT = 'https://docx-to-html-dot-startmeup-218419.rj.r.appspot.com';
  private JSREPORT_ENDPOINT = 'https://jsreportapp-dot-startmeup-218419.rj.r.appspot.com/';
  private GATEWAY = 'https://api.startmeup.com.br/Gateway/v1'

  client: AxiosInstance;

  constructor(client: AxiosInstance = axios) {
    this.client = client
  }

  save({ templateId, file }) {
    interface FormDataB extends FormData {
      _boundary: any;
    }

    const data: FormDataB = new FormData() as FormDataB

    data.append('arquivo', file)

    return this.client
      .post(`${this.GATEWAY}/documento/template/adicionaArquivo/${templateId}`, data,
        {
          headers: {
            'Content-Type': `multipart/form-data; boundary=${data._boundary}`
          }
        })
      .then((response) => response.data)
  }

  templates(options: any = null): Promise<any[]> {
    if (options) {
      return this.client
        .get(`${this.GATEWAY}/documento/template/busca`, { params: options })
        .then((response) => response.data)
    }

    return this.client
      .get(`${this.DOCUMENT_ENDPOINT}/template/lista`)
      .then(response => response.data)
  }

  parseDocxToHtml({ source }): Promise<any> {
    return this.client
      .get(`${this.PARSER_ENDPOINT}/to-html`, { params: { source } })
      .then((response) => response.data)
  }

  async syncBoletimTemplate({ html }): Promise<any> {
    const headers = {
      Authorization: 'Basic c211Omo4TW01ZFFIenhEVnZ2SFg='
    }

    const templates: { value: any[] } =
      await this.client
        .get(`${this.JSREPORT_ENDPOINT}/odata/templates?$select=name`, { headers })
        .then((response) => response.data)

    const boletimTemplate = templates.value.find(t => t.name === 'boletim-template')

    html = '<style> {#asset styles.css} </style>' + html

    return this.client
      .patch(`${this.JSREPORT_ENDPOINT}/odata/templates(${boletimTemplate._id})`, { content: html }, { headers })
      .then(response => response.data)
  }
}
